import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layouts/base"
import SEO from "../components/atoms/seo"
import Hero from "../components/molecules/hero"

const NotFoundPage = ({ data }) => {
  const siteTitle = data.wp.generalSettings.title
  const notFound = data.wp.acfOptionsGlobalOptions.notFoundTemplate

  return (
    <Layout>
      <SEO title={`404: Not Found - ${siteTitle}`} robots="noindex" slug="" />
      <Hero
        title={notFound.title || "404: Not Found"}
        lead={
          notFound.description ||
          "<p>You just hit a route that doesn't exist... the sadness.</p>"
        }
      />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    wp {
      generalSettings {
        title
      }
      acfOptionsGlobalOptions {
        notFoundTemplate {
          description
          title
        }
      }
    }
  }
`
